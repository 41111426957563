var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.isShowNav
        ? _c("nav-top", {
            attrs: { list: _vm.nav.list, "current-key": _vm.nav.currentKey }
          })
        : _vm._e(),
      _vm.isClient
        ? _c(
            "ul",
            { ref: "container", staticClass: "contact-list" },
            [
              _vm._l(_vm.nav.list, function(item, itemKey) {
                return [
                  _vm.nav.currentKey === itemKey
                    ? [
                        _vm.nav.currentKey == 0 &&
                        (!_vm.init || _vm.msgBoxData.length)
                          ? _vm._l(_vm.msgBoxData, function(row, key) {
                              return _c(
                                "li",
                                {
                                  key: itemKey + ":" + key,
                                  staticClass: "msg-item ui-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoRoom(row)
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "msg-info" }, [
                                    _c("div", {
                                      staticClass: "profile ui-bg-img",
                                      style: {
                                        backgroundImage:
                                          "url(" +
                                          _vm.$lib.cdnUrl(
                                            _vm.userData.category !== "client"
                                              ? row.userImgUrl
                                              : row.targetUserImgUrl
                                          ) +
                                          ")"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "name ui-ellipsis" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.userData.category !== "client"
                                              ? row.userName
                                              : row.targetTeamName
                                          )
                                        )
                                      ]
                                    ),
                                    _vm.isNew(row)
                                      ? _c(
                                          "div",
                                          { staticClass: "badge-new" },
                                          [_vm._v("N")]
                                        )
                                      : _vm._e(),
                                    _c("div", { staticClass: "date" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .moment(Number(row.chatDate) * 1000)
                                            .format("YY.MM.DD HH:mm")
                                        )
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "last-msg",
                                      class: {
                                        target:
                                          row.sendUseridx !==
                                          _vm.userData.useridx
                                      }
                                    },
                                    [_vm._v(_vm._s(row.msg))]
                                  ),
                                  _c("div", {
                                    staticClass:
                                      "ui-border-line ui-mt-2 ui-mb-2"
                                  })
                                ]
                              )
                            })
                          : _vm.nav.currentKey == 1 &&
                            (!_vm.init || _vm.qnaData.length)
                          ? [
                              _vm._l(_vm.qnaData, function(row) {
                                return [
                                  _c(
                                    "div",
                                    { key: itemKey + ":" + row.uid },
                                    [
                                      _c("qna-item", { attrs: { row: row } }),
                                      _c("div", {
                                        staticClass: "ui-border-line",
                                        staticStyle: { height: "10px" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              })
                            ]
                          : _c("blank-list", {
                              key: itemKey,
                              attrs: { text: _vm.emptyText }
                            })
                      ]
                    : _vm._e()
                ]
              })
            ],
            2
          )
        : _c(
            "ul",
            { ref: "container", staticClass: "contact-list artist-list" },
            [
              _vm._l(_vm.nav.list, function(item, itemKey) {
                return [
                  _vm.nav.currentKey === itemKey
                    ? [
                        _vm.nav.currentKey == 0 &&
                        (!_vm.init || _vm.msgBoxData.length)
                          ? _vm._l(_vm.msgBoxData, function(row, key) {
                              return _c(
                                "li",
                                {
                                  key: itemKey + ":" + key,
                                  staticClass: "msg-item ui-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoRoom(row)
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "msg-info" }, [
                                    _c("div", {
                                      staticClass: "profile ui-bg-img",
                                      style: {
                                        backgroundImage:
                                          "url(" +
                                          _vm.$lib.cdnUrl(
                                            _vm.userData.category !== "client"
                                              ? row.userImgUrl
                                              : row.targetUserImgUrl
                                          ) +
                                          ")"
                                      }
                                    }),
                                    _c("div", { staticClass: "profile-txt" }, [
                                      _c("div", { staticClass: "date" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .moment(
                                                Number(row.chatDate) * 1000
                                              )
                                              .format("YY.MM.DD HH:mm")
                                          )
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "chat-info-wrap" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "chat-info",
                                              class: _vm.isNew(row)
                                                ? "new-msg"
                                                : ""
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(row.genre))
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .moment(
                                                        row.playDate * 1000
                                                      )
                                                      .format("YYYY.MM.DD")
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "name ui-ellipsis" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.userData.category !== "client"
                                                ? row.userName
                                                : row.targetTeamName
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "last-msg",
                                      class: {
                                        target:
                                          row.sendUseridx !==
                                          _vm.userData.useridx
                                      }
                                    },
                                    [_vm._v(_vm._s(row.msg))]
                                  ),
                                  _c("div", {
                                    staticClass:
                                      "ui-border-line ui-mt-3 ui-mb-3"
                                  })
                                ]
                              )
                            })
                          : _vm.nav.currentKey == 1 &&
                            (!_vm.init || _vm.qnaData.length)
                          ? [
                              _vm._l(_vm.qnaData, function(row) {
                                return [
                                  _c(
                                    "div",
                                    { key: itemKey + ":" + row.uid },
                                    [
                                      _c("qna-item", { attrs: { row: row } }),
                                      _c("div", {
                                        staticClass: "ui-border-line",
                                        staticStyle: { height: "10px" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              })
                            ]
                          : _c("blank-list", {
                              key: itemKey,
                              attrs: { text: _vm.emptyText }
                            })
                      ]
                    : _vm._e()
                ]
              })
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }