<template>
  <div class="container">
    <nav-top v-if="isShowNav" :list="nav.list" :current-key="nav.currentKey" />
    
    <!-- 사용자 -->
    <ul class="contact-list" ref="container" v-if = "isClient">
      <template v-for="(item, itemKey) in nav.list">
        <template v-if="nav.currentKey === itemKey">
            <template v-if="nav.currentKey == 0 && (!init || msgBoxData.length)">
              <li
                class="msg-item ui-btn" v-for="(row, key) in msgBoxData"
                :key="`${itemKey}:${key}`"
                @click="gotoRoom(row)"
              >
                <div class="msg-info">
                  <div class="profile ui-bg-img" :style="{ backgroundImage: `url(${$lib.cdnUrl(userData.category !== 'client' ? row.userImgUrl : row.targetUserImgUrl)})` }"></div>
                  <div class="name ui-ellipsis">{{userData.category !== 'client' ? row.userName : row.targetTeamName}}</div>
                  <div class="badge-new" v-if="isNew(row)">N</div>
                  <div class="date">{{moment(Number(row.chatDate) * 1000).format('YY.MM.DD HH:mm')}}</div>
                </div>
                <div class="last-msg" :class="{ 'target': row.sendUseridx !== userData.useridx }">{{row.msg}}</div>
                <div class="ui-border-line ui-mt-2 ui-mb-2"></div>
              </li>
            </template>
            <template v-else-if="nav.currentKey == 1 && (!init || qnaData.length)">
              <template v-for="(row) in qnaData">
                  <div :key="`${itemKey}:${row.uid}`">
                      <qna-item :row="row" />
                      <div class="ui-border-line" style="height: 10px"></div>
                  </div>
              </template>
            </template>
            <blank-list
              v-else
              :key="itemKey"
              :text="emptyText"
            />
        </template>
      </template>
    </ul>    
    <!-- // 사용자 -->
    <!-- 전문가 -->
    <ul class="contact-list artist-list" ref="container" v-else>
      <template v-for="(item, itemKey) in nav.list">
        <template v-if="nav.currentKey === itemKey">
            <template v-if="nav.currentKey == 0 && (!init || msgBoxData.length)">
              <li
                class="msg-item ui-btn" v-for="(row, key) in msgBoxData"
                :key="`${itemKey}:${key}`"
                @click="gotoRoom(row)"
              >
                <div class="msg-info">
                  <div class="profile ui-bg-img" :style="{ backgroundImage: `url(${$lib.cdnUrl(userData.category !== 'client' ? row.userImgUrl : row.targetUserImgUrl)})` }"></div>
                  <div class="profile-txt">
                    <div class="date">{{moment(Number(row.chatDate) * 1000).format('YY.MM.DD HH:mm')}}</div>
                    <div class="chat-info-wrap">
                      <div class="chat-info" :class="isNew(row) ? 'new-msg' : ''">
                        <!-- 새 메세지 있을 시 new-msg 클래스 추가 / 읽을 시 new-msg 클래스 삭제 -->
                        <span>{{ row.genre }}</span>
                        <span>{{moment(row.playDate * 1000).format('YYYY.MM.DD')}}</span>
                      </div>
                    </div>
                    <div class="name ui-ellipsis">{{userData.category !== 'client' ? row.userName : row.targetTeamName}}</div>
                    <!-- <div class="badge-new" v-if="isNew(row)">N</div> -->
                  </div>
                </div>
                <div class="last-msg" :class="{ 'target': row.sendUseridx !== userData.useridx }">{{row.msg}}</div>
                <div class="ui-border-line ui-mt-3 ui-mb-3"></div>
              </li>
            </template>
            <template v-else-if="nav.currentKey == 1 && (!init || qnaData.length)">
              <template v-for="(row) in qnaData">
                  <div :key="`${itemKey}:${row.uid}`">
                      <qna-item :row="row" />
                      <div class="ui-border-line" style="height: 10px"></div>
                  </div>
              </template>
            </template>
            <blank-list
              v-else
              :key="itemKey"
              :text="emptyText"
            />
        </template>
      </template>
    </ul>
    <!-- // 전문가 -->
  </div>
</template>


<script>
import NavTop from '@/components/common/NavTop.vue'
import BlankList from '@/components/blank/BlankList'
import QnaItem from '@/components/etc/QnaItem'

export default {
    name: 'Communicate',
    components: {NavTop, BlankList, QnaItem},
    data() {
      let navBtnCb = (key = 0) => {
        this.que.listData = this.que.listData.map(() => false)
        this.scroll.lastY[this.nav.currentKey] = this.$refs.container.scrollTop
        this.nav.currentKey = key;

        if(this.nav.currentKey === 1)  {
          this.$store.commit('setGnbTitle', `문의 내역`)
        }else {
          this.$store.commit('setGnbTitle', `채팅`)
        }

        this.$router.replace({
          query: {
            nav: key,
          },
        })
        this.$nextTick().then(() => {
            this.$refs.container.scrollTop = this.scroll.lastY[this.nav.currentKey]

            let isGetData = this.nav.currentKey == 0 ? this.msgBoxData.length === 0 : this.qnaData.length === 0

            this.getData(isGetData);
        })
      }

      return {
        init: true,
        msgBoxData: [],
        qnaData: [],
        listData: {
          0: [],
          1: [],
        },
        offset: {
          listData: [0, 0],
        },
        que: {
          listData: [false, false],
        },
        scroll: {
            lastY: [0, 0],
        },
        nav: {
          list: [
            {
              label: '채팅',
              action: () => navBtnCb(0),
              request() {
                  return {}
              },
            },
            {
              label: '문의 내역',
              action: () => navBtnCb(1),
              request() {
                  return {}
              },
            },
          ],
          currentKey: 0,
        },
        history: {
          exit: false,
        },
				isShowNav: false,
        events: {
          root: {},
        },
      }
    },
    computed: {
        userData() {
          return this.$store?.state?.user?.userData
        },
        isArtist() {
          return this.userData?.category === 'artist'
        },
        isClient() {
          return this.userData?.category === 'client'
        },
        emptyText() {
          if(this.nav.currentKey == 0) {
            return this.isClient ? `개설된 채팅방이 없습니다.\n결제(섭외)가 완료되면\n채팅으로 상세 상담을\n받을 수 있어요!` : `결제(섭외)가 완료되면\n채팅으로 상세 협의를 시작하세요!\n(MY의뢰>결제내역>채팅하기)`
          }else {
            return this.isArtist ? '등록된 문의가 없습니다.\n더 활발히 활동해볼까요?' : '등록한 문의가 없습니다.';
          }
        },
    },
    created() {
      this.events.root['qna:reload'] = () => {
            this.getData(true)
      }

      this.events.root['qna:reply'] = (uid, reply) => {          
          if (uid && reply) {
              let qnaIndex = this.qnaData.findIndex?.(row => row.uid === uid)

              if (qnaIndex !== -1) {
                  this.qnaData[qnaIndex].reply = reply
                  this.qnaData[qnaIndex].replyDate = Date.now().toString().slice(0, 10)
              } else {
                  for (let key = 0, len = this.qna.list.length; key < len; key++) {
                      this.qnaData[key] = []
                  }
                  this.getData(true)
              }
          }
      }

      for (const key in this.events.root) {
        if (this.events.root.hasOwnProperty(key)) {
            const cb = this.events.root[key]
            this.$root.$on(key, cb)
        }
      }

      if(this.nav.currentKey == 0) {
          this.getQnaData(true);    
      }

      this.$eventBus.$on('refresh', route => {
        if (route.path === '/communicate') {
          this.$nextTick()
            .then(() => {
              this.$refs?.container?.scrollTo?.({ top: 0 })
            })
            .then(() => {
              this.getData(true)
            })
        }
      })

      this.getData()
    },
    
    beforeRouteEnter(to, from, next) {
        next(Vue => {
            const navNextKey = Vue.$route.query?.nav
            if (navNextKey) {
                Vue.nav.currentKey = Number(navNextKey)
            }

            let listLength = 0;

            if(Vue.nav.currentKey === 1)  {
              Vue.$store.commit('setGnbTitle', `문의 내역`)
              listLength = Vue.qnaData.length;
            }else {
              Vue.$store.commit('setGnbTitle', `채팅`)
            }

            const lastY = Vue.scroll.lastY[Vue.nav.currentKey]
            const init = listLength > 0	

            if (init) {
                Vue.$refs.container.scrollTop = lastY
            } else {
                Vue.getData(true)
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('setGnbTitle', null)
        setTimeout(() => {
            if (this.$store.state.history.back) {
                this.$runToApp({
                    androidCb: () => {
                        if (!this.history.exit) {
                            this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
                            this.history.exit = true
                            setTimeout(() => (this.history.exit = false), 2000)
                        } else {
                            location.href = 'hbscheme://appExit'
                        }
                        next(false)
                    },
                    iosCb: () => next(false),
                    webCb: () => next(false),
                })
            } else {
                this.scroll.lastY[this.nav.currentKey] = this.$refs.container.scrollTop
                next()
            }
        })
    },
    beforeDestroy() {
        for (const key in this.events.root) {
            if (this.events.root.hasOwnProperty(key)) {
                const cb = this.events.root[key]
                this.$root.$off(key, cb)
            }
        }
    },
    methods: {
      getData(init) {
        // if (this.que.listData[this.nav.currentKey]) return
        // this.que.listData[this.nav.currentKey] = true

        if (init) {
          // this.init = false
          this.offset.listData[this.nav.currentKey] = 0
        }

        if (this.nav.currentKey == 0) {
          this.getMsgList(init)
        } else {
          this.getQnaData(init);
        }
      },

      
      isNew(row) {
        // const viewMsgUid = this.$store.state.user.newMsgData.viewMsgUid
        if (row.sendUseridx == this.userData.useridx) return false
        return row.viewMsgUid < row.lastMsgUid
      },

      getMsgList(init) {
        const req = {
          method: 'get',
          url: `/chat/msgBox`,
        }

        this.$http(req)
          .then(({ data }) => {
            log(...Object.values(req), data)

            const resultData = this.$lib.resultCheck(data.resultData)

            if (resultData.success) {
              let msgBoxData = data.response.msgBoxData || []
              this.msgBoxData = msgBoxData

              let readAllMsg = true
              let lastMsgUidArr = msgBoxData.map(row => {
                row.genre = row.genre.replace('노래', '가수');
                row.genre = row.genre.replace('MC', '사회자');
                // sidemenu에서 new 표시를 위한 로직
                if (this.isNew(row)) readAllMsg = false
                return row.lastMsgUid
              })
              let lastMsgUid = Math.max(...lastMsgUidArr)
              this.$store.commit('setMsgUid', {
                mode: 'last',
                msgUid: lastMsgUid,
              })
              this.$store.commit('setReadAllMsg', readAllMsg)
            } else {
              this.$alert(resultData)
            }
          })
          .catch(log.error)
          .then(() => {
            this.init = true
            setTimeout(() => {
              this.que.listData[this.nav.currentKey] = false
            }, 1000)
            return
          })
      },
      getQnaData(init) {
            if (this.que.listData[this.nav.currentKey]) return
            this.que.listData[this.nav.currentKey] = true

            if (init) {
                this.init = false
                this.offset.listData[this.nav.currentKey] = 0
            }

            // 문의내역 있는 지 검사 시 1개만 조회, 목록 조회 시 20개 조회
            const size = this.nav.currentKey == 0 ? 1 : 20

            const req = {
                method: 'get',
                url: `/${this.userData.category}/offer/contact/${this.offset.listData[this.nav.currentKey]}/${size}`,
            }

            // if (this.isArtist) {
            //     req.params = {
            //         reply: '',
            //     }
            // }

            return this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        let qna = data?.response?.artistContactData || []
												if(this.nav.currentKey == 0) {
													if(qna.length == 0) {
                            this.isShowNav = false;
														return;
													}else {
                            this.isShowNav = true;
                            return;
                          }
													
												}

                        // this.listData[this.nav.currentKey] = init ? qna : this.listData[this.nav.currentKey].concat(qna)
                        this.qnaData = init ? qna : this.qnaData.concat(qna)
                        this.offset.listData[this.nav.currentKey] += qna.length
                    } else {
                        this.$alert(resultData)
                    }
                    return
                })
                .catch(e => {
                    log.error(e)
                    return
                })
                .then(() => {
                    this.init = true
                    setTimeout(() => {
                        this.que.listData[this.nav.currentKey] = false
                    }, 1000)
                    return
                })
        },
      gotoRoom(row) {
        if (this.$lib.execNgWordProcess(this.$store)) return

        const url = {
          path: `/message/${row.offerUid}/${row.targetUseridx}`,
        }
        if (!this.isClient) {
          url.query = { ttn: row.targetTeamName }
        }
        this.$router.push(url)
      },
      getDataOnScroll() {
        let scrollTop = this.$refs.container.scrollTop
        let scrollHeight = this.$refs.container.scrollHeight

        if (scrollHeight <= scrollTop + window.innerHeight) {
          this.getData()
        }
      }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';
#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;

  .contact-list {
    padding: 0 3rem;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    .msg-item {
      margin-top: 1rem;
      .msg-info {
        overflow: hidden;
        display: flex;

        .profile {
          margin-right: 1rem;
          float: left;
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          background-color: #efefef;
        }
        .name {
          float: left;
          font-size: 1.8rem;
          color: #5a5a5a;
          line-height: 5rem;
          flex: 1;
        }
        .date {
          float: right;
          color: #a2a2a2;
          font-size: 1.3rem;
          line-height: 5rem;
        }
      }
      .last-msg {
        position: relative;
        margin-top: 0.7rem;
        margin-left: 6rem;
        color: #000;
        font-size: 1.8rem;
        line-height: 2.2rem;
        min-height: 4.2rem;
        padding: 1rem 1.8rem;
        border: 1px solid #f0f3f9;
        border-radius: 0.5rem;
        background-color: #f0f3f9;
        // 텍스트 말줄임표
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:not(.target) {
          @include tri-right(8, 4, -16);
          background-color: #f0f3f9;
        }
        &.target {
          @include tri-top(10, -20, 20);
        }
      }
      .badge-new {
        float: left;
        margin: 1.5rem 1rem 0;
        width: 1.7rem;
        height: 1.7rem;
        line-height: 1.8rem;
        border-radius: 50%;
        // background-color: #a059b6;
        background-color: #ff8f9f;
        color: #fff;
        font-size: 1rem;
        text-align: center;
        font-weight: 400;
      }
    }
    &.artist-list {
      .msg-item {
        .msg-info {
          .profile {
            margin-right: 2rem;
            width: 6.6rem;
            height: 6.6rem;
            margin-top: 2.5rem;
            flex-shrink:0;
          }
          .profile-txt {
            display: flex;
            flex-direction: column;
            flex: 1;
            .date {
              color: #666666;
              font-size: 1.8rem;
              text-align: right;
              line-height: normal;
            }
            .chat-info-wrap {
              margin-top: 0.4rem;
              .chat-info {
                position: relative;
                display: inline-block;
                span {
                  font-size: 2.4rem;
                  line-height: 3.4rem;
                  & ~ span {
                    position: relative;
                    margin-left: 2rem;
                    &::after {
                      position: absolute;
                      top: 50%;
                      left: -1.05rem;
                      width: 0.1rem;
                      height: 1.5rem;
                      background-color: #999999;
                      transform: translateY(-50%);
                      content: '';
                    }
                  }
                }
                &.new-msg {
                  span {
                    font-weight: 600;
                  }
                  &::after {
                    position: absolute;
                    top: 0;
                    right: -1.2rem;
                    width: 0.6rem;
                    height: 0.6rem;
                    border-radius: 50%;
                    background-color: #F33841;
                    content:'';
                  }
                }
              }
            }
            .name {
              flex: none;
              margin-top: 0.5rem;
              line-height: normal;
            }
          }
        }
        .last-msg {
          margin-top: 1.3rem;
          margin-left: 8.6rem;
        }
      }
    }
  }
}
</style>
